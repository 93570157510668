<template>



    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-0'>
                <span class='block font-medium mb-3 text-xl'>Giderler Toplamı</span>
                <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/giderler.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
            <div class="mt-2">
                <div class='text-900 font-bold text-xl'>
                    {{ formatPrice(report.totalInput) }}<span class='font-light ml-1'>&#8378;</span>
                    + {{ formatPrice(report.totalInputTax) }} &#8378; (KDV)<span class='font-light ml-1'></span><br>     
                </div>

                <div class="font-bold text-2xl text-red-600 mt-3">
                    {{ formatPrice(report.totalInputWithTax) }}<span class='font-light ml-1'>&#8378;</span>
                </div>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-0'>
                <span class='block font-medium mb-3 text-xl'>Gelirler Toplamı</span>
                <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/gelirler.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>

            
            <div class="mt-2">
                <div class='text-900 font-bold text-xl'>
                    {{ formatPrice(report.totalOutput) }}<span class='font-light ml-1'>&#8378;</span>
                    + {{ formatPrice(report.totalOutputTax) }} &#8378; (KDV)<span class='font-light ml-1'></span><br>     
                </div>

                <div class="font-bold text-2xl text-blue-600 mt-3">
                    {{ formatPrice(report.totalOutputWithTax) }}<span class='font-light ml-1'>&#8378;</span>
                </div>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-0'>
                <span class='block font-medium mb-3 text-xl'>Gelir - Gider Sonucu</span>
                <div class='flex align-items-center justify-content-center border-round'
                     style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/gelirgidertoplamlar.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
            <div class="mt-1">
                <div class='text-900 font-bold text-xl' :class='profitColor'>
                    Toplam: {{ formatPrice(report.profitTotal)}} <span class='font-light ml-1'>&#8378;</span><br>
                    KDV: {{ formatPrice(report.profitTax) }} <span class='font-light ml-1'>&#8378;</span><br>
                    Genel Toplam: {{ formatPrice(report.profitWithTax) }} <span class='font-light ml-1'>&#8378;</span>
                </div>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-0'>
                <span class='block font-medium mb-3 text-xl'>Karlılık Oranı</span>
                <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/karlilikorani.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
     
            <div>
                <div class="font-bold text-7xl" :class='profitMarginColor'>
                     <span class='font-light ml-1'>%</span> {{ report.profitMargin }}
                </div>
            </div>

        </div>
    </div>

</template>

<script>
export default {
    name: 'InvoiceReport',
    props: {
        report: {},
    },
    computed: {
        profitMarginColor() {
            if(this.report.profitMargin > 0)
            {
                return "text-green-900";
            }
            else {
                return "text-red-900"
            }
        },
        profitColor() {
            if(this.report.profitMargin > 0)
            {
                return "text-green-600";
            }
            else {
                return "text-red-600"
            }
        }
    },
    methods : {
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
    }
};
</script>

<style scoped lang='scss'>
</style>