export function showSuccessMessage(_this, message) {
    _this.$toast.add({
        severity: 'success',
        summary: 'İşlem Başarılı',
        detail: message,
        life: 6000,
    });
}

export async function showSuccessMessageWithRedirect(_this, message, redirectName, params) {
    await _this.$router.push({ name: redirectName, params: params }, () => {
        _this.$toast.add({
            severity: 'success',
            summary: 'İşlem Başarılı',
            detail: message,
            life: 6000,
        });
    });

}

export function showErrorMessage(_this, message) {
    _this.$toast.add({
        severity: 'error',
        summary: 'İşlem Başarısız',
        detail: message,
        life: 6000,
    });
}

export function showValidationMessage(_this, message) {
    _this.$toast.add({
        severity: 'warn',
        summary: 'Eksik Ya Da Hatalı Giriş',
        detail: message,
        life: 6000,
    });
}